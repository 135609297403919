import React from "react";
import { useState, useRef, useEffect } from "react";
import FAQCard from "../FAQCard/FAQCard";
import "./FAQ.css";

const FAQ = () => {
  const [faqVisible, setFaqVisible] = useState(false);
  const faqRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setFaqVisible(entry.isIntersecting);
    });
    observer.observe(faqRef.current);
  }, []);
  return (
    <section id="FAQ">
      <h1>
        Frequently Asked <span>Questions</span>
      </h1>
      <article ref={faqRef} className={faqVisible ? "faq_visible" : undefined}>
        <FAQCard
          question="Is Any Data Logged?"
          details="No. Customer privacy is one of our main priorities, meaning we will never log any of your information, allowing you to use our service knowing you have 100% privacy."
        />
        <FAQCard
          question="How Fast Is This?"
          details="Quite simply, that varies. Where we can't put an exact number on it, your crypto will be sent as soon as we have received 2 confirmations."
        />
        <FAQCard
          question="What's The Max Exchange Amount?"
          details="Wanting this service to be usable by a majority, there is no maximum exchange amount"
        />
        <FAQCard
          question="Where Can I Contact You?"
          details="We have our own community <a href='https://discord.com/channels/@me/985597376972587008'>discord server</a> and <a href='https://discord.com/channels/@me/985597376972587008'>telegram</a>."
        />
      </article>
    </section>
  );
};

export default FAQ;
