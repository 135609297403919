import React from "react";

const Arrow = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.357832 7.74144L7.50031 -9.53674e-07L14.6423 7.74091C15.1192 8.25845 15.1192 9.09615 14.6423 9.61211C14.1653 10.1291 13.3931 10.1286 12.9161 9.61211L7.49982 3.74186L2.08255 9.61264C1.6056 10.1291 0.833313 10.1291 0.356854 9.61264C-0.119115 9.09562 -0.119115 8.25845 0.357832 7.74144Z"
        fill="black"
      />
    </svg>
  );
};

export default Arrow;
