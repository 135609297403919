import React from "react";

const Logs = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6767 6.99998L14.3433 2.33331H0V37.6666H40V13V11.6666V6.99998H17.6767ZM20 32.2186L13.724 25.9426L15.6093 24.0573L18.6667 27.1146V17H21.3333V27.1146L24.3907 24.0573L26.276 25.9426L20 32.2186ZM38.6667 11.6666H21.01L18.6293 8.33331H38.6667V11.6666Z"
        fill="#2ED573"
      />
    </svg>
  );
};

export default Logs;
