import React from "react";
import "./Footer.css";
import { Link } from "react-scroll";
import Discord from "../../Assets/svgs/Discord";
import Telegram from "../../Assets/svgs/Telegram";
import Logo from "../../Assets/svgs/Logo";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <section>
        <aside>
          <h1 id="footer_logo">
            <Link to="exchange" smooth={true} offset={-350}>
              <Logo />
            </Link>
          </h1>
          <h6>
            Cheap, Safe, Private. Transfer your currencies with our secure
            network today.{" "}
          </h6>
        </aside>
        <aside>
          <div>
            <span>
              <Link to="features" smooth={true} offset={-150}>
                Features
              </Link>
            </span>
            <span>
              <Link to="FAQ" smooth={true} offset={-150}>
                FAQs
              </Link>
            </span>
          </div>
          <div onClick={() => navigate("/tos")}>
            <span>Terms</span>
          </div>
          <div>
            <div
              onClick={() =>
                window.open("https://discord.gg/zVQTtqSfZu", "_blank").focus()
              }
            >
              <Discord width="20px" />
            </div>
            <div
              onClick={() =>
                window.open("https://t.me/exchangespaceTG", "_blank").focus()
              }
            >
              <Telegram width="20px" />
            </div>
          </div>
        </aside>
      </section>
    </footer>
  );
};

export default Footer;
