import React from "react";
import Exchange from "../../components/Exchange/Exchange";
import FAQ from "../../components/FAQ/FAQ";
import Features from "../../components/Features/Features";
import { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import {
  updateModalState,
  updateCreateTransactionState,
} from "../../redux/userSlice";
import "./Home.css";

const Home = () => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => ({ ...state.user }));
  return (
    <main>
      <Exchange />
      <Features />
      <FAQ />
      <div className="taxModalParent" id={modal ? "triggerModal" : undefined}>
        <div>
          <h1>Alert</h1>
          <p>
            The amount of crypto you receive heavily depends on the crypto coin
            price and exchange rate, you may or may not receive the exact
            amount.
          </p>
          <button
            onClick={() => {
              dispatch(updateModalState(false));
              dispatch(updateCreateTransactionState(true));
            }}
          >
            Got it!
          </button>
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </main>
  );
};

export default Home;
