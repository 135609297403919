import React from "react";
import "./Transaction.css";
import ArrowTwo from "../../Assets/svgs/ArrowTwo";
import Wallet from "../../Assets/svgs/Wallet";
import Tick from "../../Assets/svgs/Tick";
import ExchangeIcon from "../../Assets/svgs/ExchangeIcon";
import TransactionIcon from "../../Assets/svgs/TransactionIcon";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "axios";

const Transaction = () => {
  //! initializations
  const { id } = useParams();

  //! useState
  const [data, setData] = useState();
  const [expireryState, setExpireryState] = useState(0);
  const [webhook, setWebhook] = useState("");
  const [webhookState, setWebhookState] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState({
    deposit: false,
    conversation: false,
    confirmation: false,
    transaction: false,
  });

  //! useEffect

  useEffect(() => {
    const getTransactionData = async () => {
      try {
        let { data } = await axios.post(
          `https://backend.exchangespace.io/api/getTransaction`,
          { id }
        );
        setData(data.payload);
        if (transactionStatus?.transaction) setExpireryState(2);
        setTransactionStatus(data?.payload?.info);
      } catch (error) {
        console.log(error);
      }
    };
    getTransactionData();
  }, [id]);

  useEffect(() => {
    if (expireryState !== 2) {
      if (new Date() > new Date(data?.validUntil)) {
        setExpireryState(1);
      }
      const time = setInterval(() => {
        if (new Date() > new Date(data?.validUntil)) {
          setExpireryState(1);
        }
      }, [5000]);
      return () => clearInterval(time);
    }
  }, [data]);

  useEffect(() => {
    if (data?.webhook !== "" && data?.webhook !== undefined) {
      setWebhookState(true);
      setWebhook(data?.webhook);
    }
  }, [data]);

  useEffect(() => {
    if (data !== undefined) {
      let obj = data?.info;
      setTransactionStatus(obj);
    }
  }, [data]);

  useEffect(() => {
    const sendNotif = async () => {
      if (transactionStatus?.transaction) {
        setExpireryState(2);
      }
    };
    sendNotif();
  }, [transactionStatus]);

  useEffect(() => {
    if (data !== undefined && !transactionStatus?.transaction) {
      const checkStatus = async () => {
        try {
          console.log(data?.specific_id);
          let output;
          let statusInterval = setInterval(async () => {
            output = await axios.get(
              `https://api.changenow.io/v1/transactions/${data?.specific_id}/85d3fbff85a81d1e4d4b692db8b8b9e331970cc34d4a1c05a8fb6a2dc3eacf19`,
              { id }
            );
            if (output?.data?.status === "confirming") {
              await setTransactionStatus({
                ...transactionStatus,
                deposit: true,
              });
            } else if (output?.data?.status === "exchanging") {
              await setTransactionStatus({
                ...transactionStatus,
                deposit: true,
                conversation: true,
              });
            } else if (output?.data?.status === "sending") {
              await setTransactionStatus({
                ...transactionStatus,
                deposit: true,
                conversation: true,
                confirmation: true,
              });
            } else if (output?.data?.status === "finished") {
              if (data?.webhook !== undefined || webhook.length !== 0) {
                let testData = await axios.post(`${data?.webhook || webhook}`, {
                  embeds: [
                    {
                      title: "Exchange space: Instant Crypto Exchange",
                      description:
                        "Coins exchanged succesfully, check your address.",
                      url: `https://exchangespace.io/transaction/${data?._id}`,
                      color: 7419530,
                      footer: {
                        text: "Flawless automatic crypto exchanging with low fees, no logging and no sign up",
                        icon_url: "https://i.ibb.co/GR54rbm/HEDONISM.png",
                      },
                    },
                  ],
                });
              }
              await setTransactionStatus({
                ...transactionStatus,
                deposit: true,
                conversation: true,
                confirmation: true,
                transaction: true,
              });
              clearInterval(statusInterval);
            }
          }, [5000]);
          return () => clearInterval(statusInterval);
        } catch (error) {
          console.log(error);
        }
      };
      checkStatus();
    }
  }, [data]);

  useEffect(() => {
    if (transactionStatus?.deposit) {
      updateTransactionData();
    }
  }, [transactionStatus]);

  //! MISC
  const updateTransactionData = async () => {
    try {
      await axios.patch(
        `https://backend.exchangespace.io/api/updateTransaction`,
        {
          id,
          transactionStatus,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const changeWebhook = async () => {
    try {
      if (webhook === "") {
        return toast.error(`Enter webhook`, {
          id: "first",
        });
      } else if (!webhook.startsWith("https://discord.com/api/webhooks")) {
        return toast.error(`Enter valid webhook`, {
          id: "first",
        });
      } else if (webhookState) {
        return;
      } else {
        setWebhookState(true);
        toast.success("Changed webhook", {
          id: "success",
          style: {
            color: "#55efc4",
          },
        });
        await axios.patch(`https://exchangespace.io/api/updateWebhook`, {
          id,
          webhook,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  let timeElement = null;
  if (expireryState === 0 && !transactionStatus?.transaction) {
    timeElement = <h6>{new Date(data?.validUntil).toLocaleTimeString()}</h6>;
  } else if (expireryState === 1 && !transactionStatus?.transaction) {
    timeElement = (
      <h6
        style={{
          color: "#e84393",
          fontWeight: "bolder",
          borderRadius: "10px",
          padding: "0.5rem 1rem",
          backgroundColor: "#191a1e",
        }}
      >
        Transaction Expired!
      </h6>
    );
  } else {
    timeElement = (
      <h6>
        <h6
          style={{
            marginLeft: "0",
            color: "#55efc4",
            fontWeight: "bolder",
            borderRadius: "10px",
            fontSize: "1.3rem",
            backgroundColor: "#191a1e",
          }}
        >
          Transaction Completed!
        </h6>
      </h6>
    );
  }

  //! MISC

  return (
    <main id="Transaction">
      <aside>
        <article className="transaction_input">
          <h2>Send</h2>
          <span>
            Coin: <h6>{data?.cryptoFirst?.toUpperCase()}</h6>
          </span>
          <span>
            Amount: <h6>{data?.amountFirst}</h6>
          </span>
          <span>
            Address:
            <h6>{data?.serverAddress}</h6>
          </span>
        </article>
        <ArrowTwo width="20" />
        <article className="transaction_input">
          <h2>Receive</h2>
          <span>
            Coin: <h6>{data?.cryptoSecond.toUpperCase()}</h6>
          </span>
          <span>
            Amount: <h6>{data?.amountSecond}</h6>
          </span>
          <span>
            Address:
            <h6>{data?.userAddress}</h6>
          </span>
        </article>
      </aside>
      <aside>
        <div
          className={`transaction_status_wrapper ${
            transactionStatus?.deposit ? "completed" : undefined
          }`}
        >
          <div>
            <Wallet width="20" />
          </div>
          <span>
            {transactionStatus?.deposit ? "Deposited" : "Awaiting Deposit"}
          </span>
        </div>
        <ArrowTwo width="20" />
        <div
          className={`transaction_status_wrapper ${
            transactionStatus?.conversation ? "completed" : undefined
          }`}
        >
          <div>
            <ExchangeIcon width="20" />
          </div>
          <span>
            {transactionStatus?.conversation ? "Converted" : "Converting"}
          </span>
        </div>
        <ArrowTwo width="20" />
        <div
          className={`transaction_status_wrapper ${
            transactionStatus?.confirmation ? "completed" : undefined
          }`}
        >
          <div>
            <Tick width="20" />
          </div>
          <span>
            {transactionStatus?.confirmation
              ? "Confirmed"
              : "Awaiting Confirmation"}
          </span>
        </div>
        <ArrowTwo width="20" />
        <div
          className={`transaction_status_wrapper ${
            transactionStatus?.transaction ? "completed" : undefined
          }`}
        >
          <div>
            <TransactionIcon width="20" />
          </div>
          <span>
            {transactionStatus?.transaction
              ? "Funds sent"
              : "Awaiting Transaction"}
          </span>
        </div>
      </aside>
      <aside>
        <article id="transaction_details">
          <h5>
            Order ID:
            <h6>{id}</h6>
          </h5>
          <h5>
            Created At:
            <h6>{new Date(data?.createdAt).toLocaleString()}</h6>
          </h5>
          <h5>
            Valid until:
            {timeElement}
          </h5>
        </article>
        <article id="transaction_webhook">
          <h2>Notification</h2>
          <div id="transaction_webhook_input_wrapper">
            <input
              type="text"
              disabled={webhookState ? true : false}
              placeholder="Enter Discord Webhook (optional)"
              value={webhook}
              onChange={(e) => setWebhook(e.target.value)}
            />
            <div></div>
          </div>
          <button onClick={changeWebhook}>Confirm</button>
        </article>
      </aside>
      <Toaster position="bottom-center" reverseOrder={false} />
    </main>
  );
};

export default Transaction;
