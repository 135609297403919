import { createSlice } from "@reduxjs/toolkit";
import io from "socket.io-client";

const userSlice = createSlice({
  name: "user",
  transactionInfo: {
    amountOne: 0,
    amountTwo: 0,
    currencyOne: "",
    currencyTwo: "",
    userAddress: "",
    serverAddress: "",
  },
  initialState: {
    loading: false,
    success: null,
    error: null,
    modal: false,
    createTransaction: false,
    rateInfo: {
      firstCoinRate: "",
      secondCoinRate: "",
      convertUSD: "",
    },
  },
  reducers: {
    updateRates: (state, { payload }) => {
      state.rateInfo = payload;
    },
    updateLoading: (state, { payload }) => {
      state.loading = payload;
    },
    updateTransactionData: (state, { payload }) => {
      state.transactionInfo = payload;
    },
    updateModalState: (state, { payload }) => {
      state.modal = payload;
    },
    updateCreateTransactionState: (state, { payload }) => {
      state.createTransaction = payload;
    },
  },
});

export const {
  updateTransactionData,
  updateRates,
  updateLoading,
  updateModalState,
  updateCreateTransactionState,
} = userSlice.actions;
export default userSlice.reducer;
