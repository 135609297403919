import React from "react";

const Discord = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M31.57 9.26006C29.3839 8.23722 27.0714 7.51027 24.6933 7.09839C24.3966 7.63339 24.0516 8.35506 23.8116 8.92672C21.2856 8.54173 18.7159 8.54173 16.19 8.92672C15.9251 8.30203 15.6274 7.69174 15.2983 7.09839C12.9181 7.51095 10.6038 8.23961 8.41662 9.26506C4.06495 15.8417 2.88495 22.2551 3.47495 28.5767C6.01521 30.4948 8.86929 31.957 11.91 32.8984C12.5931 31.9582 13.1974 30.9632 13.7166 29.9234C12.7291 29.547 11.777 29.0835 10.8716 28.5384C11.11 28.3617 11.3433 28.1767 11.5683 27.9867C17.0533 30.5517 23.0116 30.5517 28.4316 27.9867C28.66 28.1767 28.8933 28.3617 29.1283 28.5384C28.2266 29.0817 27.2716 29.5484 26.2783 29.9251C26.7966 30.9653 27.4009 31.9604 28.085 32.9001C31.128 31.9593 33.984 30.4958 36.525 28.5751C37.2166 21.2484 35.3433 14.8934 31.57 9.26006ZM14.4633 24.6884C12.8166 24.6884 11.4666 23.1517 11.4666 21.2801C11.4666 19.4084 12.7883 17.8684 14.4633 17.8684C16.1383 17.8684 17.4883 19.4051 17.46 21.2801C17.4616 23.1517 16.1383 24.6884 14.4633 24.6884ZM25.5366 24.6884C23.89 24.6884 22.54 23.1517 22.54 21.2801C22.54 19.4084 23.8616 17.8684 25.5366 17.8684C27.2116 17.8684 28.5616 19.4051 28.5333 21.2801C28.5333 23.1517 27.2116 24.6884 25.5366 24.6884Z" />
    </svg>
  );
};

export default Discord;
