import React from "react";

const Tick = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1213 5.21048L13.295 21.0356C12.1231 22.2078 10.222 22.2078 9.04892 21.0356L0.879298 12.8654C-0.293099 11.6932 -0.293099 9.79192 0.879298 8.61952C2.05192 7.4469 3.95286 7.4469 5.12497 8.61908L11.1726 14.6668L24.875 0.964367C26.0476 -0.208252 27.9487 -0.207364 29.1208 0.964367C30.293 2.13677 30.293 4.03741 29.1213 5.21048Z"
        fill="#191B1F"
      />
    </svg>
  );
};

export default Tick;
