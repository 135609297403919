import React from "react";

const ExchangeIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.17279 30.6435C2.9799 25.7145 2.97978 20.2854 5.17279 15.3564C6.4293 12.5322 8.3206 10.0668 10.7019 8.09132L17.883 14.777V1.38327H3.4967L8.20225 5.7642C0.616147 12.1825 -2.2401 22.5937 1.9025 31.9047C3.57411 35.6619 6.2765 38.9527 9.71764 41.4217C13.0539 43.8152 17.0182 45.3984 21.182 46L21.7249 42.7433C14.2509 41.6636 8.06326 37.1401 5.17279 30.6435Z" />
      <path d="M48.0839 31.9047C50.6387 26.1624 50.6387 19.8376 48.0839 14.0953C46.4123 10.3381 43.7098 7.04728 40.2688 4.57845C36.9325 2.18475 32.9682 0.601554 28.8044 0L28.2615 3.2567C35.7354 4.33644 41.923 8.85974 44.8135 15.3565C47.0065 20.2855 47.0065 25.7146 44.8135 30.6436C43.557 33.4678 41.6657 35.9332 39.2845 37.9087L32.1034 31.223V44.6167H46.4896L41.7934 40.2445C44.4972 37.9643 46.6465 35.1354 48.0839 31.9047Z" />
    </svg>
  );
};

export default ExchangeIcon;
