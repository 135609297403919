import React from "react";

const ArrowTwo = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M40.7684 23.8256C39.1263 22.3453 36.472 22.3453 34.8299 23.8256L25.1998 32.507V3.78605C25.1998 1.69615 23.3225 0 21 0C18.6775 0 16.8002 1.69615 16.8002 3.78605V32.507L7.17009 23.8256C5.52797 22.3453 2.87371 22.3453 1.23159 23.8256C-0.410529 25.3059 -0.410529 27.6987 1.23159 29.1791L21 47L40.7684 29.1791C42.4105 27.6987 42.4105 25.3059 40.7684 23.8256Z" />
    </svg>
  );
};

export default ArrowTwo;
