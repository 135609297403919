import React from "react";

const Crypto = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_200)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 40C8.95375 40 0 31.0462 0 20C0 8.95375 8.95375 0 20 0C31.0462 0 40 8.95375 40 20C40 31.0462 31.0462 40 20 40ZM28.9862 17.525C29.3787 14.905 27.3825 13.4963 24.655 12.5562L25.54 9.00625L23.38 8.46875L22.5175 11.925C21.95 11.7825 21.3675 11.65 20.7862 11.5175L21.655 8.03875L19.495 7.5L18.61 11.0487C18.14 10.9412 17.6775 10.8363 17.23 10.7238L17.2325 10.7125L14.2525 9.96875L13.6775 12.2762C13.6775 12.2762 15.2812 12.6438 15.2475 12.6663C16.1225 12.885 16.28 13.4637 16.2537 13.9237L15.2462 17.9675C15.3062 17.9825 15.3837 18.005 15.4712 18.0387L15.2425 17.9825L13.83 23.6475C13.7225 23.9125 13.4513 24.3112 12.8387 24.16C12.8612 24.1912 11.2688 23.7687 11.2688 23.7687L10.1962 26.2412L13.0087 26.9425C13.5312 27.0738 14.0437 27.2113 14.5475 27.34L13.6538 30.93L15.8125 31.4675L16.6975 27.9175C17.2875 28.0763 17.86 28.2237 18.42 28.3638L17.5375 31.8987L19.6975 32.4362L20.5912 28.8538C24.2762 29.5513 27.0462 29.27 28.2125 25.9375C29.1525 23.255 28.1663 21.7063 26.2275 20.6975C27.64 20.3725 28.7025 19.4438 28.9862 17.525ZM24.0487 24.4475C23.3825 27.1313 18.8638 25.68 17.3988 25.3162L18.5863 20.56C20.0513 20.9263 24.7475 21.65 24.0487 24.4475ZM24.7175 17.4862C24.1087 19.9275 20.3487 18.6863 19.13 18.3825L20.205 14.07C21.4237 14.3737 25.3525 14.94 24.7175 17.4862Z"
          fill="#2ED573"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_200">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Crypto;
