import React from "react";
import "./Terms.css";

const Terms = () => {
  return (
    <main id="TOS">
      <h1>Terms of Service</h1>
      <p>
        Last updated on <span>Oct 17th, 2022</span>
      </p>
      <h4>
        Please read these terms of service (the "Terms") carefully before using
        our website. By accessing or using any part of the site, you agree to be
        bound by these Terms. If you do not agree to all the terms and
        conditions herein, then you may not access the website or use any
        ExchangeSpace services.
      </h4>
      <h3>OVERVIEW</h3>
      <p>
        These Terms constitute a legal agreement between us (the
        "ExchangeSpace") and users, clients, visitors and others (without
        limitation), who access the Website (as defined below) (the "User",
        "You", "Your"). ExchangeSpace offers the set of related web pages and
        services located under a single domain named ExchangeSpace.io (the
        "Website", the "Service"), including all information, tools and services
        available from this Site to you, given upon your acceptance of all
        terms, conditions, policies, and notices stated here.
      </p>

      <h3>
        SECTION 1 - <span>ExchangeSpace.io TERMS</span>{" "}
      </h3>
      <p>
        You hereby confirm that you are an individual, legal person or other
        organisation with full capacity for civil rights and civil conducts when
        you use the Service. By agreeing to these Terms, you represent that you
        are at least the age of majority in your state or province of residence
        or you have consent from your legal representative or guardian to use
        the Service.
      </p>
      <p>
        You may not use our services and products for any illegal or
        unauthorized purpose nor may you, in the use of the Service, violate any
        laws in any applicable jurisdiction (including but not limited to
        copyright laws).
      </p>
      <p>
        A breach or violation of any of the Terms will result in an immediate
        termination of the services provided to you.
      </p>
      <h3>
        SECTION 2 - <span>GENERAL CONDITIONS</span>
      </h3>
      <p>
        The headings used in this agreement are included for convenience only
        and will not limit or otherwise affect these Terms.
      </p>
      <p>
        We reserve the right to refuse service to anyone for any reason at any
        time.
      </p>
      <p>
        You understand that your content, may be transferred unencrypted and
        involve (a) transmissions over various networks; and (b) changes to
        conform and adapt to technical requirements of connecting networks or
        devices.
      </p>
      <p>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any
        portion of the Service, use of the Service, or access to the Service or
        any contact on the Website through which the service is provided,
        without express written permission by us.
      </p>
      <h3>
        SECTION 3 -{" "}
        <span>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</span>
      </h3>
      <p>
        We are not responsible if information made available on the Website is
        not accurate, complete or current. The material on the Website is
        provided for general information only and should not be relied upon or
        used as the sole basis for making decisions without consulting primary,
        more accurate, more complete or more timely sources of information. Any
        reliance on the material on the Website is at your own risk.
      </p>
      <p>
        The Website may contain certain historical information. Historical
        information, necessarily, is not current and is provided for your
        reference only. We reserve the right to modify the contents of the
        Website at any time and we have no obligation to update any information
        on this Website. You agree that it is your responsibility to monitor
        changes to the Website.
      </p>
      <h3>
        SECTION 4 - <span>MODIFICATIONS TO THE SERVICE AND PRICES</span>{" "}
      </h3>
      <p>Prices for our services are subject to change without notice.</p>
      <p>
        We reserve the right at any time to modify or discontinue the Website
        (or any part or content thereof) without notice at any time.
      </p>
      <p>
        We shall not be liable to you or to any third-party for any
        modification, price change, suspension or discontinuance of the service.
      </p>
      <h3>
        SECTION 5 - <span>USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</span>{" "}
      </h3>
      <p>
        If, at our request, you send certain specific submissions or without a
        request from us you send creative ideas, suggestions, proposals, plans,
        or other materials, whether online, by email, by postal mail, or
        otherwise (collectively, 'comments'), you agree that we may, at any
        time, without restriction, edit, copy, publish, distribute, translate
        and otherwise use in any media any comments that you forward to us.
      </p>
      <h3>
        SECTION 6 - <span>PROHIBITED USES</span>{" "}
      </h3>
      <p>
        In addition to other prohibitions as set forth in these Terms, you are
        prohibited from using the Website or its content: (a) for any unlawful
        purpose; (b) to solicit others to perform or participate in any unlawful
        acts; (c) to violate any international, federal, provincial or state
        regulations, rules, laws, or local ordinances; (d) to infringe upon or
        violate our intellectual property rights or the intellectual property
        rights of others; (e) to harass, abuse, insult, harm, defame, slander,
        disparage, intimidate, or discriminate based on gender, sexual
        orientation, religion, ethnicity, race, age, national origin, or
        disability; (f) to submit false or misleading information; (g) to upload
        or transmit viruses or any other type of malicious code that will or may
        be used in any way that will affect the functionality or operation of
        the Service or of any related website, other websites, or the Internet;
        (h) to collect or track the personal information of others; (i) to spam,
        phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or
        immoral purpose; or (k) to interfere with or circumvent the security
        features of the Service or any related website, other websites, or the
        Internet. If fraudulent or stolen funds are sent to our addresses, the
        funds may be frozen until information about the source and legality of
        the funds is received. We reserve the right to terminate your use of the
        Service or any related website for violating any of the prohibited uses.
      </p>
      <h3>
        SECTION 7 -{" "}
        <span>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</span>
      </h3>
      <p>
        We do not guarantee, represent or warrant that your use of our services
        will be uninterrupted, timely, secure or error-free.
      </p>
      <p>
        You agree that from time to time we may remove the service for
        indefinite periods of time or cancel the service at any time, without
        notice to you.
      </p>
      <p>
        In no case shall ExchangeSpace be liable for lost profits, lost revenue,
        lost savings, loss of data, replacement costs, or any similar damages.
      </p>
      <p>
        THE WEBSITE AND THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS AND
        WITHOUT ANY WARRANTIES OR INDEMNITIES OF ANY KIND, EITHER EXPRESSED OR
        IMPLIED. YOU ASSUME ALL RESPONSIBILITY AND RISK WITH RESPECT TO YOUR USE
        OF THE WEBSITE AND SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY THE
        APPLICABLE LAWS, WE OR OUR AFFILIATES DO NOT ACCEPT ANY LIABILITY FOR
        ANY DAMAGE OR LOSS, INCLUDING LOSS OF BUSINESS, REVENUE, OR PROFITS, OR
        LOSS OF OR DAMAGE TO DATA, EQUIPMENT, OR SOFTWARE (DIRECT, INDIRECT,
        PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY OR
        OTHERWISE), RESULTING FROM ANY USE OF, OR INABILITY TO USE, THIS WEBSITE
        AND THE SERVICES OR THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES,
        SERVICES OR CONTENT ON THIS REGARDLESS OF THE BASIS, UPON WHICH THE
        LIABILITY IS CLAIMED.
      </p>
      <p>
        IF APPLICABLE LAWS DO NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION
        OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO
        THE EXTENT PERMITTED BY APPLICABLE LAWS. YOU UNDERSTAND AND AGREE THAT
        IT IS YOUR OBLIGATION TO ENSURE COMPLIANCE WITH ANY LEGISLATION RELEVANT
        TO YOUR COUNTRY OF DOMICILE CONCERNING USE OF THE WEBSITE, AND THAT YOU
        SHOULD NOT ACCEPT ANY LIABILITY FOR ANY ILLEGAL OR UNAUTHORIZED USE OF
        THE WEBSITE. YOU AGREE TO BE SOLELY RESPONSIBLE FOR ANY APPLICABLE TAXES
        IMPOSED UNDER YOUR TAX RESIDENCY REGULATIONS.
      </p>
      <p>
        IN NO EVENT SHALL WE, OUR DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS BE
        LIABLE FOR ANY LOSS RESULTING FROM HACKING, TAMPERING, VIRUS
        TRANSMISSION OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICES, YOUR
        ACCOUNT, OR ANY INFORMATION CONTAINED THEREIN; FOR PRODUCTS NOT BEING
        AVAILABLE FOR USE; FOR IMPROPER FUNCTIONALITY, TECHNICAL FAULTS AND
        DOWNTIME OF THE TECHNICAL INFRASTRUCTURE.
      </p>
      <p>
        WE DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE
        SERVICES AND YOU ACKNOWLEDGE AND AGREE THAT THE WEBSITE MAY BE
        INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE OF OUR CONTROL.
      </p>
      <h3>
        SECTION 8 - <span>INDEMNIFICATION</span>{" "}
      </h3>
      <p>
        You hereby agree to indemnify the ExchangeSpace, any of its officers,
        directors, employees and agents and its affiliated and related entities
        from and against any claims, costs, losses, liabilities, damages,
        expenses and judgments of any and every kind arising out of, relating
        to, or incurred in connection with any claim, complaint, audit, inquiry,
        or other proceeding, that arises or relates to: (a) any actual or
        alleged breach of your representations, warranties, or obligations set
        forth in these Terms; (b) your wrongful or improper use of the services;
        (c) any other party’s access or use of the services with your data; (d)
        arising out of a breach of any warranty, representation, or obligation
        hereunder.
      </p>
      <p>
        You shall not have any claim of any nature whatsoever against us for any
        failure to carry out any of our obligations under these Terms as a
        result of a force majeure - causes beyond our control, including but not
        limited to any strike, lockout, shortage of labor or materials, delays
        in transport, in securing any permit, consent or approval required by
        us, for the supply of products, delay by any sub-contractor or supplier
        of ours accidents of any kind, riot, political or civil disturbances,
        the elements, by an act of state or government including regulatory
        action imposed or any other authority or any other cause whatsoever
        beyond our absolute and direct control.
      </p>
      <h3>
        SECTION 9 - <span>SEVERABILITY</span>{" "}
      </h3>
      <p>
        In the event that any provision of these Terms is determined to be
        unlawful, void or unenforceable, such provision shall nonetheless be
        enforceable to the fullest extent permitted by applicable law, and the
        unenforceable portion shall be deemed to be severed from these Terms,
        such determination shall not affect the validity and enforceability of
        any other remaining provisions.
      </p>
      <h3>
        SECTION 10 - <span>TERMINATION</span>{" "}
      </h3>
      <p>
        The obligations and liabilities of the parties incurred prior to the
        termination date shall survive the termination of this agreement for all
        purposes.
      </p>
      <p>
        These Terms are effective unless and until terminated by either you or
        us. You may terminate these Terms at any time by notifying us that you
        no longer wish to use our Services, or when you cease using our Website.
      </p>
      <h3>
        SECTION 11 - <span>ENTIRE AGREEMENT</span>
      </h3>
      <p>
        The failure of us to exercise or enforce any right or provision of these
        Terms shall not constitute a waiver of such right or provision.
      </p>
      <p>
        These Terms and any policies or operating rules posted by us on the
        Website or in respect of the Service constitutes the entire agreement
        and understanding between you and us and govern your use of the Service,
        superseding any prior or contemporaneous agreements, communications and
        proposals, whether oral or written, between you and us (including, but
        not limited to, any prior versions of these Terms).
      </p>
      <p>
        Any ambiguities in the interpretation of these Terms shall not be
        construed against the drafting party.
      </p>
      <h3>
        SECTION 12 - <span>TAX</span>{" "}
      </h3>
      <p>
        The taxation of digital assets has been limited to the most
        jurisdictions. You may be subject to certain tax implications when
        buying or selling digital assets. Tax depends on price change of crypto
        coins and exchange rate, you may not get exact amount
      </p>
      <h3>
        SECTION 13 - <span>CHANGES TO TERMS OF SERVICE</span>
      </h3>
      <p>
        You can review the most current version of the Terms at any time at this
        page.
      </p>
      <p>
        We reserve the right, at our sole discretion, to update, change or
        replace any part of these Terms by posting updates and changes to our
        website. It is your responsibility to check our website periodically for
        changes. Your continued use of or access to our website or the Service
        following the posting of any changes to these Terms constitutes
        acceptance of those changes.
      </p>
      <h3>
        SECTION 14 - <span>RISK</span>{" "}
      </h3>
      <p>
        You acknowledge and agree that you shall use the Services at your own
        risk. You should, therefore, carefully consider whether such exchange is
        suitable for you in light of your circumstances and financial resources.
      </p>
      <h3>
        SECTION 15 - <span>FEES</span>
      </h3>
      <p>
        You agree to pay us the fees for exchange completed via our Services:
      </p>
      <p>2% - %5 of the amount for every exchange.</p>
      <p>Exl Network rates.</p>
      <h3>
        SECTION 16 - <span>INTELLECTUAL PROPERTY RIGHTS</span>
      </h3>
      <p>
        The ExchangeSpace exclusively owns all rights, title, and interest in
        the "ExchangeSpace" name, "ExchangeSpace" logo, the names of individual
        services and their logos, patents, copyrights (including rights in
        derivative works), trademarks or service marks, logos and designs, trade
        secrets, and other intellectual property embodied by, or contained on
        the Website and relating webpages, documentation etc. You shall never
        use any ExchangeSpace logos, marks or other intellectual property for
        commercial and public use without our express permission, unless
        otherwise explicitly indicated by the ExchangeSpace. You shall not copy,
        reproduce, distribute, modify, create derivative works of, publicly
        display, publicly perform, republish, download, store, transmit or
        otherwise use any of the ExchangeSpace logos, marks or intellectual
        property. You also shall not make any public statement or issue any
        press release related to us or our services without our express
        permission. Under these Terms you are entitled to use any logos or marks
        for your personal, non-commercial purposes exclusively.
      </p>
      <h3>
        SECTION 17 - <span>RESTRICTED ACTIVITIES</span>
      </h3>
      <p>
        In connection with your use of the Service, or in the course of your
        interactions with the ExchangeSpace, you shall not:
      </p>
      <ul>
        <li>
          breach these Terms or any policy approved by the ExchangeSpace and
          agreed by you.
        </li>
        <li>violate any law, statute, ordinance, or regulation.</li>
        <li>
          infringe the ExchangeSpace or any third party's copyright, patent,
          trademark, exchange secret or other intellectual property rights, or
          rights of publicity or privacy.
        </li>
        <li>
          act in a manner that is defamatory, trade libellous, threatening or
          harassing to our employees, agents or other users.
        </li>
        <li>provide false, inaccurate or misleading Information.</li>
        <li>
          engage in potentially fraudulent or suspicious activity and/or
          transactions
        </li>
      </ul>
      <h3>
        SECTION 18 - <span>DISPUTES RESOLUTION</span>
      </h3>
      <p>
        All disputes and disagreements that might arise from these Terms shall
        be resolved by means of negotiations. You agree that for the purposes of
        the settlement of disputes between you and us, an e-mail correspondence
        with the authorized persons of the ExchangeSpace at:
        admin@ExchangeSpace.io shall be the effective and binding method of
        communication. If the parties cannot agree on the subject of the dispute
        within thirty (30) days, the dispute shall be shall be referred to and
        finally resolved by the relevant court. To the extent allowed by the
        applicable law, you agree that you will bring any claim arising from or
        connected with these Terms of use within one (1) year from the date of
        which such claim arose. Otherwise such claims will be irrevocably
        waived.
      </p>

      <h3>
        SECTION 19 - <span>CONTACT INFORMATION</span>
      </h3>
      <p>
        Questions about these Terms should be sent to us at
        https://discord.gg/zVQTtqSfZu .
      </p>
    </main>
  );
};

export default Terms;
