import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Terms from "./pages/Terms/Terms";
import Transaction from "./pages/Transaction/Transaction";

function Router() {
  return (
    <BrowserRouter className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/transaction/:id" element={<Transaction />} />
        <Route path="/tos" element={<Terms />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
