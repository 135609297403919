import React from "react";

const Support = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_183)">
        <path
          d="M39.2719 19.3388C39.3195 19.1744 39.3215 19.0013 39.3215 18.8217V16.8988C39.3215 12.3493 37.6037 8.09645 34.4392 4.92419C31.2719 1.74884 27.0353 0 22.4873 0C17.9393 0 13.7214 1.74884 10.5539 4.92419C7.38959 8.09645 5.67798 12.3491 5.67798 16.8988V18.8217C5.67798 19.0014 5.67996 19.1744 5.72758 19.3388C2.82781 19.5686 0.510864 22.6606 0.510864 26.4293C0.510864 30.2151 2.82917 33.3189 5.74725 33.524C6.52736 34.5752 7.78577 35.214 9.1257 35.214C11.455 35.214 13.3468 33.3177 13.3468 30.9869V21.8717C13.3468 19.6134 11.6152 17.763 9.38878 17.6501V16.8988C9.38866 9.50354 15.1198 3.71068 22.4998 3.71068C29.8798 3.71068 35.6108 9.50354 35.6108 16.8988V17.6501C33.3844 17.7634 31.6527 19.6136 31.6527 21.8717V30.9869C31.6527 32.5113 32.4636 33.8495 33.6763 34.5933C32.2944 36.6262 30.3521 38.2347 28.0532 39.2162C27.4242 38.5152 26.512 38.0733 25.4982 38.0733H20.7381C18.8449 38.0733 17.3048 39.6135 17.3048 41.5066V41.5665C17.3048 43.4597 18.8449 44.9999 20.7381 44.9999H25.4982C26.8911 44.9999 28.092 44.1657 28.6299 42.9711C30.9029 42.1445 32.9794 40.8336 34.7274 39.09C36.0293 37.7914 37.091 36.3107 37.891 34.7024C38.4243 34.4144 38.8945 34.0149 39.2589 33.524C42.1768 33.3184 44.4891 30.2148 44.4891 26.4293C44.4891 22.6604 42.1721 19.5684 39.2719 19.3388Z"
          fill="#2ED573"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_183">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Support;
