import React from "react";
import { useRef, useState, useEffect } from "react";
import "./Features.css";
import Key from "../../Assets/svgs/Key";
import Logs from "../../Assets/svgs/Logs";
import Coins from "../../Assets/svgs/Coins";
import Lightning from "../../Assets/svgs/Lightning";
import Support from "../../Assets/svgs/Support";
import Crypto from "../../Assets/svgs/Crypto";

const Features = () => {
  const [featuresVisible, setFeaturesVisible] = useState(false);
  //! This is same as document.querySelector()
  const featuresRef = useRef();
  //! We need useEffect to track component mount
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      //? Entires are basically components your screen renders on browser, what you see
      const entry = entries[0];
      //? Takes specific component shown on screen
      //? entry.isIntersecting true/false checks if component is on your viewport
      setFeaturesVisible(entry.isIntersecting);
    });
    //? Tracks this element
    observer.observe(featuresRef.current);
  }, []);
  return (
    <section id="features">
      <h1>
        What we <span>Offer</span>
      </h1>
      <article
        id={featuresVisible ? "features_visible" : undefined}
        ref={featuresRef}
      >
        <div className="features_wrapper">
          <Key width="20px" />
          <h4>No Sign Up / KYC </h4>
          <p>
            Wanting our site to be accessible to all, we understand that many
            prefer to remain anonymous online. So, we don't ask for anything; no
            need to verify your age and certainly no need for your personal
            information.
          </p>
        </div>

        <div className="features_wrapper">
          <Logs width="20px" />
          <h4>No Logs</h4>
          <p>
            Everyone likes a little privacy which is where our zero log policy
            comes in; this means we don't track / save any addresses,
            transaction IDs or anything else for that matter.
          </p>
        </div>

        <div className="features_wrapper">
          <Coins width="20px" />
          <h4>Low Fees</h4>
          <p>
            For offering possibly the fastest service around, we also take one
            of the lowest fees - these being around the area of 2 to 5%.
            Although, fees are based on coin price and exchange rate.
          </p>
        </div>

        <div className="features_wrapper">
          <Lightning width="20px" />
          <h4>Fastest</h4>
          <p>
            Requiring only 2 confirmations, you'll be sent your desired crypto
            as soon as we've verified that you truly have sent your crypto
            first.
          </p>
        </div>

        <div className="features_wrapper">
          <Support width="20px" />
          <h4>Support</h4>
          <p>
            Our support team are always ready to help with any questions or
            queries, so don't hesitate to reach out to us if you ever need to.
          </p>
        </div>

        <div className="features_wrapper">
          <Crypto width="20px" />
          <h4>200+ Different Coins</h4>
          <p>
            With 200+ of coins you can exchange to and from, it allows you to be
            able to get exactly what you might want, whenever you want it
          </p>
        </div>
      </article>
    </section>
  );
};

export default Features;
