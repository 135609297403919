import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../../Assets/svgs/Arrow";
import ExchangeIcon from "../../Assets/svgs/ExchangeIcon";
import "./Exchange.css";

import { toast } from "react-hot-toast";
import axios from "axios";
import Search from "../../Assets/svgs/Search";

const Exchange = () => {
  //! Initialization
  const exchangeRef = useRef();
  const navigate = useNavigate();
  //! useState
  const [exchangeVisible, setExchangeVisible] = useState(false);

  //? All coin data
  const [firstAllCoins, setFirstAllCoins] = useState([]);
  const [firstCoinDrop, setFirstCoinDrop] = useState(false);

  const [secondAllCoins, setSecondAllCoins] = useState([]);
  const [secondCoinDrop, setSecondCoinDrop] = useState(false);

  const [firstSearch, setFirstSearch] = useState("");
  const [firstSearchData, setFirstSearchData] = useState([]);
  const [secondSearch, setSecondSearch] = useState("");
  const [secondSearchData, setSecondSearchData] = useState([]);

  //? Inputs
  const [firstInput, setFirstInput] = useState(0);
  const [secondInput, setSecondInput] = useState(0);
  const [address, setAddress] = useState("");
  const [rateId, setRateId] = useState("");
  const [loading, setLoading] = useState(false);

  //? Range
  const [cryptoRange, setCryptoRange] = useState({
    min: "",
    max: "",
  });

  //? Coins
  const [firstCoin, setFirstCoin] = useState({
    ticker: "btc",
    name: "Bitcoin",
    image: "https://content-api.changenow.io/uploads/btc_d8db07f87d.svg",
    hasExternalId: false,
    isFiat: false,
    featured: true,
    isStable: false,
    supportsFixedRate: true,
    network: "btc",
    tokenContract: null,
    buy: true,
    sell: true,
    legacyTicker: "btc",
  });
  const [secondCoin, setSecondCoin] = useState({
    ticker: "eth",
    name: "Ethereum",
    image: "https://content-api.changenow.io/uploads/eth_f4ebb54ec0.svg",
    hasExternalId: false,
    isFiat: false,
    featured: true,
    isStable: false,
    supportsFixedRate: true,
    network: "eth",
    tokenContract: null,
    buy: true,
    sell: true,
    legacyTicker: "eth",
  });

  //! useEffects
  useEffect(() => {
    if (firstInput > cryptoRange?.max || firstInput < cryptoRange?.min) {
      setSecondInput(0);
    }
  }, [firstInput, cryptoRange?.max, cryptoRange?.min]);

  useEffect(() => {
    let result = secondAllCoins.filter((e) =>
      e.name.toLowerCase().startsWith(secondSearch)
    );
    setSecondSearchData(result);
  }, [secondSearch, secondAllCoins]);

  useEffect(() => {
    const fetchCoinData = async () => {
      try {
        let { data } = await axios.get(
          "https://api.changenow.io/v2/exchange/currencies?active=true&flow=fixed-rate&buy=true&sell=true",
          {
            headers: {
              "x-changenow-api-key":
                "d246b23d784ea563af554d96ed7e5f2dd92f7c7a8d0a415f36895b346acdf01e",
            },
          }
        );
        setSecondAllCoins(data);
        setFirstAllCoins(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCoinData();
  }, []);

  useEffect(() => {
    const checkCrypto = async () => {
      try {
        setFirstInput(0);
        setSecondInput(0);
        let range = await axios.get(
          `https://api.changenow.io/v1/exchange-range/fixed-rate/${firstCoin?.legacyTicker}_${secondCoin?.legacyTicker}?api_key=d246b23d784ea563af554d96ed7e5f2dd92f7c7a8d0a415f36895b346acdf01e&useRateId=true`
        );
        setCryptoRange({
          min: range?.data?.minAmount,
          max: range?.data?.maxAmount,
        });
      } catch (error) {
        console.log(error);
      }
    };
    checkCrypto();
  }, [firstCoin?.legacyTicker, secondCoin?.legacyTicker]);

  useEffect(() => {
    let result = firstAllCoins.filter((e) =>
      e.name.toLowerCase().startsWith(firstSearch)
    );
    setFirstSearchData(result);
  }, [firstSearch, firstAllCoins]);

  useEffect(() => {
    const checkPrice = async () => {
      try {
        if (firstInput !== 0 && firstInput !== "") {
          console.log(firstInput);
          setLoading(true);
          let { data } = await axios.get(
            `https://api.changenow.io/v1/exchange-amount/fixed-rate/${firstInput}/${firstCoin?.legacyTicker}_${secondCoin?.legacyTicker}?api_key=d246b23d784ea563af554d96ed7e5f2dd92f7c7a8d0a415f36895b346acdf01e&useRateId=true`
          );
          setLoading(false);
          setSecondInput(data?.estimatedAmount);
          setRateId(data?.rateId);
        }
      } catch (error) {
        return toast.error(
          `Minimum: ${cryptoRange?.min}, Maximum: ${cryptoRange?.max}`,
          {
            id: "first",
          }
        );
      }
    };
    checkPrice();
  }, [firstInput]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setExchangeVisible(entry.isIntersecting);
    });
    observer.observe(exchangeRef.current);
  }, []);

  //! Misc
  const handleExchange = async () => {
    try {
      if (firstInput === 0 || firstInput === "")
        return toast.error(`Choose proper amount`, {
          id: "first",
        });
      if (firstInput > cryptoRange?.max || firstInput < cryptoRange?.min) {
        return toast.error(
          `Minimum: ${cryptoRange?.min}, Maximum: ${cryptoRange?.max}`,
          {
            id: "first",
          }
        );
      }
      if (address === "")
        return toast.error(`Enter address`, {
          id: "first",
        });
      let { data } = await axios.get(
        `https://api.changenow.io/v2/validate/address?currency=${secondCoin?.legacyTicker}&address=${address}`
      );
      if (!data.result)
        return toast.error(data.message, {
          id: "first",
        });
      let result = await axios.post(
        `https://backend.exchangespace.io/api/transaction`,
        {
          userAddress: address,
          cryptoFirst: firstCoin,
          cryptoSecond: secondCoin,
          amountFirst: firstInput,
          amountSecond: secondInput,
          rateId,
        }
      );

      navigate(`/transaction/${result.data.payload}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section
      id="exchange"
      ref={exchangeRef}
      className={exchangeVisible ? "exchange_visible" : undefined}
    >
      <h1>
        Exchange <span>Area</span>
      </h1>
      <article>
        <div id="exchange_conversation_wrapper">
          <fieldset>
            <div className="exchange_input_wrapper">
              <input
                type="number"
                onChange={(e) => setFirstInput(e.target.value)}
                value={parseFloat(firstInput)}
              />
              <button
                onClick={() => setFirstCoinDrop(!firstCoinDrop)}
                className={firstCoinDrop ? "dropBtn" : undefined}
              >
                <span>{firstCoin?.ticker?.toUpperCase()}</span>
                <Arrow width="20px" />
              </button>
              <div
                className="exchange_dropdown"
                id={firstCoinDrop ? "triggeredExchangeDropdown" : undefined}
              >
                <div className="swap_search">
                  <Search />
                  <input
                    value={firstSearch}
                    onChange={(e) =>
                      setFirstSearch(e.target.value.toLowerCase())
                    }
                    type="text"
                    placeholder="Search"
                  />
                </div>
                <ul>
                  {firstSearchData?.map((output, index) => {
                    return (
                      <li
                        className={
                          secondCoin?.name === output.name
                            ? "prohibited_coin"
                            : undefined
                        }
                        key={index}
                        onClick={() => {
                          if (secondCoin?.name === output.name)
                            return toast.error("Can not exchange same coins", {
                              id: "first",
                            });
                          setFirstCoinDrop(false);
                          setFirstCoin(output);
                        }}
                      >
                        {output?.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* <h6>
              {inputRate} {firstCoinData?.coin} <span>≈</span> $
              {USDFinalRate.toFixed(2)}
            </h6> */}
          </fieldset>
          <div
            id="exchange_loader"
            className={loading ? "rotateSVG" : undefined}
          >
            <ExchangeIcon width="20px" />
          </div>
          <fieldset>
            <div className="exchange_input_wrapper">
              <button
                className={secondCoinDrop ? "dropBtnTwo" : undefined}
                onClick={() => setSecondCoinDrop(!secondCoinDrop)}
              >
                <span>{secondCoin?.ticker.toUpperCase()}</span>
                <Arrow width="20px" />
              </button>
              <input disabled type="text" value={secondInput} />
              <div
                style={{ left: 0, right: "auto" }}
                className="exchange_dropdown"
                id={secondCoinDrop ? "triggeredExchangeDropdownTwo" : undefined}
              >
                <div className="swap_search">
                  <Search />
                  <input
                    value={secondSearch}
                    onChange={(e) =>
                      setSecondSearch(e.target.value.toLowerCase())
                    }
                    type="text"
                    placeholder="Search"
                  />
                </div>
                <ul>
                  {secondSearchData?.map((output, index) => {
                    return (
                      <li
                        className={
                          firstCoin.name === output.name
                            ? "prohibited_coin"
                            : undefined
                        }
                        key={index}
                        onClick={() => {
                          if (firstCoin.name === output.name)
                            return toast.error("Can not exchange same coins", {
                              id: "first",
                            });
                          setSecondCoinDrop(false);
                          setSecondCoin(output);
                        }}
                      >
                        {output?.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* <h6>
              {secondFinalRate.toFixed(4)} {secondCoinData?.coin} <span>≈</span>{" "}
              ${USDFinalRate.toFixed(2)}
            </h6> */}
          </fieldset>
        </div>
        <div
          id="excahnge_address"
          className={exchangeVisible ? "address_visible" : undefined}
        >
          <input
            type="text"
            placeholder="Enter your address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <div></div>
        </div>
        <button onClick={() => handleExchange()}>Exchange now</button>
      </article>
    </section>
  );
};

export default Exchange;
