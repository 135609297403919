import React from "react";

const Wallet = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.125 12.1875V9.375C28.125 8.33941 27.2855 7.5 26.25 7.5H5.625C4.59094 7.5 3.75 6.65906 3.75 5.625C3.75 4.59094 4.59094 3.75 5.625 3.75H26.25C27.2855 3.75 28.125 2.91047 28.125 1.875C28.125 0.839531 27.2855 0 26.25 0H5.625C2.52316 0 0 2.52322 0 5.625V7.5V22.5C0 24.5711 1.67906 26.25 3.75 26.25H26.25C27.2855 26.25 28.125 25.4106 28.125 24.375V21.5625C29.1605 21.5625 30 20.7231 30 19.6875V14.0625C30 13.0269 29.1605 12.1875 28.125 12.1875ZM25.3125 18.75C24.277 18.75 23.4375 17.9106 23.4375 16.875C23.4375 15.8394 24.277 15 25.3125 15C26.348 15 27.1875 15.8394 27.1875 16.875C27.1875 17.9106 26.348 18.75 25.3125 18.75Z"
        fill="#191B1F"
      />
    </svg>
  );
};

export default Wallet;
