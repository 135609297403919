import React from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Menu from "../../Assets/svgs/Menu";
import "./Navbar.css";
import Discord from "../../Assets/svgs/Discord";
import Telegram from "../../Assets/svgs/Telegram";
import Logo from "../../Assets/svgs/Logo";

const Navbar = () => {
  const navigate = useNavigate();
  const [resNav, triggerResNav] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);
  const changeNavBg = () => {
    window.scrollY >= 100 ? setScrollNav(true) : setScrollNav(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);
  return (
    <nav id={scrollNav ? "scrollNav" : undefined}>
      <h1 id="logo">
        <Link
          onClick={() => {
            navigate("/");
            triggerResNav(false);
          }}
          to="exchange"
          smooth={true}
          offset={-350}
        >
          <Logo />
        </Link>
      </h1>
      <aside id="nav_links">
        <ul id={resNav ? "resNavDrop" : undefined}>
          <li>
            <Link
              onClick={() => {
                navigate("/");
                triggerResNav(false);
              }}
              to="features"
              smooth={true}
              offset={-150}
            >
              Features
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                navigate("/");
                triggerResNav(false);
              }}
              to="FAQ"
              smooth={true}
              offset={-150}
            >
              FAQs
            </Link>
          </li>
          <div id="nav_social_wrapper">
            <div
              onClick={() =>
                window.open("https://discord.gg/zVQTtqSfZu", "_blank").focus()
              }
            >
              <Discord width="20px" />
            </div>
            <div
              onClick={() =>
                window.open("https://t.me/exchangespaceTG", "_blank").focus()
              }
            >
              <Telegram width="20px" />
            </div>
          </div>
        </ul>
        <div id="nav_menu" onClick={() => triggerResNav(!resNav)}>
          <Menu width="20px" />
        </div>
      </aside>
    </nav>
  );
};

export default Navbar;
