import React from "react";

const Coins = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_158)">
        <path
          d="M15.4688 19.6875C10.0408 19.6875 5.625 24.1033 5.625 29.5312C5.625 34.9592 10.0408 39.375 15.4688 39.375C20.8967 39.375 25.3125 34.9592 25.3125 29.5312C25.3125 24.1033 20.8967 19.6875 15.4688 19.6875ZM15.4688 36.5625C11.592 36.5625 8.4375 33.408 8.4375 29.5312C8.4375 25.6545 11.592 22.5 15.4688 22.5C19.3455 22.5 22.5 25.6545 22.5 29.5312C22.5 33.408 19.3455 36.5625 15.4688 36.5625Z"
          fill="#2ED573"
        />
        <path
          d="M16.875 30.9375V26.7188C16.875 25.9421 16.2454 25.3125 15.4688 25.3125H14.0625C13.2859 25.3125 12.6562 25.9421 12.6562 26.7188C12.6562 27.4954 13.2859 28.125 14.0625 28.125V30.9375C13.2859 30.9375 12.6562 31.5671 12.6562 32.3438C12.6562 33.1204 13.2859 33.75 14.0625 33.75H16.875C17.6516 33.75 18.2812 33.1204 18.2812 32.3438C18.2812 31.5671 17.6516 30.9375 16.875 30.9375Z"
          fill="#2ED573"
        />
        <path
          d="M28.1133 0C19.7335 0 11.25 2.41497 11.25 7.03125V14.6622C4.76701 16.5048 0 22.4656 0 29.5312C0 38.0608 6.93923 45 15.4688 45C18.8677 45 22.0036 43.8851 24.5589 42.0198C25.7358 42.1195 26.9261 42.1875 28.125 42.1875C36.511 42.1875 45 39.7725 45 35.1562V7.03125C45 2.41497 36.5048 0 28.1133 0ZM2.8125 29.5312C2.8125 22.5529 8.49041 16.875 15.4688 16.875C22.4471 16.875 28.125 22.5529 28.125 29.5312C28.125 36.5096 22.4471 42.1875 15.4688 42.1875C8.49041 42.1875 2.8125 36.5096 2.8125 29.5312ZM42.1875 35.1562C42.1875 36.8358 36.5789 39.375 28.125 39.375C27.8773 39.375 27.6529 39.3561 27.4112 39.3512C28.4503 38.0898 29.28 36.6551 29.8811 35.107C34.7031 34.9109 39.35 33.9231 42.1876 32.1693V35.1562H42.1875ZM42.1875 28.125C42.1875 29.6275 37.6836 31.8133 30.6821 32.2577C30.8406 31.371 30.9375 30.4629 30.9375 29.5312C30.9375 29.0211 30.9094 28.5175 30.8609 28.02C35.3254 27.7365 39.5432 26.7726 42.1875 25.1381V28.125ZM42.1875 21.0938C42.1875 22.6218 37.5273 24.8545 30.3205 25.2454C29.8897 23.7546 29.2438 22.3565 28.4127 21.0867C33.7441 21.05 39.0624 20.0384 42.1875 18.1069V21.0938ZM42.1875 14.0625C42.1875 15.742 36.5673 18.2812 28.1133 18.2812C27.3779 18.2812 26.6734 18.2552 25.984 18.2186C23.221 15.6487 19.531 14.0625 15.4688 14.0625C14.9991 14.0625 14.5365 14.0918 14.0773 14.133C14.0752 14.1099 14.0625 14.0854 14.0625 14.0625V11.0842C17.246 13.0464 22.694 14.0625 28.1133 14.0625C33.5425 14.0625 39.0002 13.0456 42.1875 11.0818V14.0625ZM28.1133 11.25C19.6662 11.25 14.0625 8.71075 14.0625 7.03125C14.0625 5.35175 19.6662 2.8125 28.1133 2.8125C36.5742 2.8125 42.1875 5.35175 42.1875 7.03125C42.1875 8.71075 36.5742 11.25 28.1133 11.25Z"
          fill="#2ED573"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_158">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Coins;
