import React from "react";
import Arrow from "../../Assets/svgs/Arrow";
import "./FAQCard.css";
import { useState } from "react";

const FAQCard = ({ question, details }) => {
  const [FAQDrop, triggerFAQDrop] = useState(false);
  return (
    <div className="FAQCard_wrapper" onClick={() => triggerFAQDrop(!FAQDrop)}>
      <div className="FAQCard_header">
        <h2>{question}</h2>
        <div className={FAQDrop ? "FAQSVG" : undefined}>
          <Arrow width="20px" />
        </div>
      </div>
      <p
        className={FAQDrop ? "FAQDrop" : undefined}
        dangerouslySetInnerHTML={{ __html: details }}
      />
    </div>
  );
};

export default FAQCard;
