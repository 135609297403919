import React from "react";

const Telegram = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30.2016 5.32955C30.2016 5.32955 33.0346 4.22487 32.7985 6.90768C32.7198 8.01238 32.0116 11.8788 31.4607 16.0609L29.572 28.4492C29.572 28.4492 29.4147 30.264 27.9981 30.5797C26.5816 30.8952 24.4568 29.475 24.0633 29.1593C23.7485 28.9226 18.1612 25.3718 16.1938 23.6359C15.6429 23.1624 15.0133 22.2155 16.2724 21.1108L24.5355 13.2202C25.4799 12.2733 26.4242 10.064 22.4894 12.7468L11.4721 20.2429C11.4721 20.2429 10.2129 21.0319 7.85211 20.3218L2.73687 18.7437C2.73687 18.7437 0.848174 17.5601 4.07469 16.3764C11.9443 12.6678 21.6238 8.88031 30.2016 5.32955Z" />
    </svg>
  );
};

export default Telegram;
