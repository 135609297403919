import React from "react";

const Lightning = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.956 0L8.21863 25.5868H17.7649L10.4271 45L36.7798 19.4115H27.2319L33.956 0Z"
        fill="#2ED573"
      />
    </svg>
  );
};

export default Lightning;
