import React from "react";

const Key = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_212)">
        <path
          d="M36.1119 4.33917C33.6006 1.82776 30.2616 0.444824 26.7102 0.444824C23.1586 0.444824 19.8196 1.82776 17.3084 4.33936C13.035 8.61257 12.2878 15.0933 15.0593 20.1428L11.3125 23.8897C11.2495 23.8119 11.1828 23.7365 11.111 23.6647L8.45188 21.0056C7.91649 20.4702 7.20463 20.1753 6.44748 20.1753C5.69053 20.1753 4.97867 20.47 4.44309 21.0056C3.33772 22.111 3.33772 23.9092 4.44309 25.0146L7.10221 27.6739C7.174 27.7455 7.24917 27.8122 7.32697 27.8752L6.31124 28.8909L4.83923 27.4189C4.30384 26.8833 3.59198 26.5884 2.83483 26.5884C2.0775 26.5884 1.36564 26.8833 0.830436 27.4189C0.294853 27.9539 0 28.6657 0 29.4231C0 30.1804 0.294853 30.8921 0.830436 31.4275L2.30752 32.9047C0.866893 34.4553 0.896773 36.8867 2.40543 38.3959C3.15318 39.1436 4.14767 39.5554 5.20493 39.5554C6.26219 39.5554 7.25669 39.1436 8.00444 38.3957L20.7635 25.6366C22.5871 26.5505 24.6105 27.0372 26.7098 27.0372C30.2612 27.0372 33.6002 25.6543 36.1115 23.1427C41.2961 17.9586 41.2961 9.52324 36.1119 4.33917ZM30.1323 17.1629C28.2423 19.0529 25.1781 19.0529 23.2881 17.1629C21.3982 15.273 21.3982 12.2087 23.2881 10.3187C25.1781 8.42878 28.2423 8.42878 30.1323 10.3187C32.0223 12.2087 32.0223 15.273 30.1323 17.1629Z"
          fill="#2ED573"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_212">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Key;
