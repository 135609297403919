import React from "react";

const TransactionIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2525 16.25C21.9429 16.25 22.5025 16.8096 22.5025 17.5C22.5025 18.141 22.02 18.6694 21.3983 18.7416L21.2525 18.75H6.77126L10.8864 22.8661C11.337 23.3167 11.3717 24.0258 10.9904 24.5161L10.8864 24.6339C10.4358 25.0845 9.72675 25.1192 9.23638 24.7379L9.11862 24.6339L2.86862 18.3839C2.1154 17.6307 2.59292 16.366 3.61047 16.2575L3.75251 16.25H21.2525ZM20.7661 5.26213L20.8839 5.36612L27.1339 11.6161C27.8871 12.3693 27.4096 13.634 26.392 13.7425L26.25 13.75H8.75C8.05964 13.75 7.5 13.1904 7.5 12.5C7.5 11.859 7.98255 11.3306 8.60422 11.2584L8.75 11.25H23.2312L19.1161 7.13388C18.6655 6.68328 18.6308 5.97424 19.0121 5.48388L19.1161 5.36612C19.5667 4.91551 20.2758 4.88085 20.7661 5.26213Z"
        fill="black"
      />
    </svg>
  );
};

export default TransactionIcon;
